const proficiency = [
  {
    type: 'smartwatch',
    title: 'Smart Watch App Development',
    content:
      'We help you add your apps on the smartwatch platform. We deliver smartwatch apps to add value to UX, ' +
      'improve accessibility and allow intercommunications. We can enhance your mobile app experience ' +
      'with a wrist-friendly smartwatch app.',
  },
  {
    type: 'fitness',
    title: 'Fitness Wearables',
    content:
      'We develop fitness trackers to observe and regulate overall wellbeing and activity levels such as step counter, ' +
      'sleep hours, quality of sleep and more.',
  },
  {
    type: 'healthcare',
    title: 'Healthcare and Medical Wearables',
    content:
      "With technology like Electrocardiogram (ECG/EKG), our wearables update patients' Electronic Health Records (EHR) " +
      "in real-time. From the doctors' point of view, we develop applications for wearable surgical aids, like VR headsets.",
  },
  {
    type: 'lifestyle',
    title: 'Lifestyle Wearables',
    content:
      'We create applications for devices such as HUDs, LED smart clothing, Bluetooth headsets, and smart glasses and ' +
      'watches which adds up especially to cater to e-learning, media, and social media platforms.',
  },
  {
    type: 'security',
    title: 'Security Wearables',
    content:
      'Security technology embedded in wearables such as wristbands, jewellery and watch – allowing users to make ' +
      'secure payments just by waving or tapping the device on a point-of-sale (POS).',
  },
  {
    type: 'safety',
    title: 'Safety Wearables',
    content:
      'Our safety and security wearable software development solutions support security devices that facilitate ' +
      'biometric fingerprinting, facial and voice recognition, as well as applications for one-touch alert and emergency contact services.',
  },
];

export default proficiency;
