import React from 'react';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet-async';
import PageHero from '@components/hero';
import Heading from '@components/pageHeading';
import { SectionHeading } from '@components/Common';
import { Layout, Container } from 'layouts';

import ProficiencyList from '@constants/wearableTech';

// Assets
import headerImage from '@assets/services/wearable-tech/wearable-header.jpg';

import FitnessWearableIcon from '@assets/services/wearable-tech/fitness-wearables.svg';
import HealthcareMedIcon from '@assets/services/wearable-tech/healthcare-med-wearables.svg';
import LifestyleWearablesIcon from '@assets/services/wearable-tech/lifestyle-wearables.svg';
import SafetyWearablesIcon from '@assets/services/wearable-tech/safety-wearables.svg';
import SecurityWearablesIcon from '@assets/services/wearable-tech/security-wearables.svg';
import SmartWatchAppIcon from '@assets/services/wearable-tech/smart-watch-app.svg';

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  margin-top: 30px;
`;

const ProcessIconElem = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: start;
  flex-flow: column;
  padding: 15px 20px;
  flex: 0 0 33.33%;
  svg {
    margin-bottom: 20px;
  }
  @media (max-width: ${props => props.theme.breakpoints.l}) {
    flex: 0 0 100%;
  }
`;

const Content = styled.div`
  font-weight: 500;
  font-size: 14px;
`;

const ProcessWrapper = styled.div`
  background-color: ${props => props.theme.colors.primary.lighter};
`;
const ListWrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  max-width: 280px;
  margin: 0 auto;
`;

const ListItem = styled.div`
  font-size: 14px;
  margin: 5px;
  font-weight: 600;
  &::before {
    content: ' ';
    width: 10px;
    height: 10px;
    display: inline-flex;
    margin-right: 15px;
    border-radius: 50%;
    background-color: ${props => props.theme.colors.primary.base};
  }
`;

const ItemTitle = styled.p`
  font-size: 20px;
  font-weight: 600;
`;

const WearableTech = () => {
  const iconMap = {
    smartwatch: <SmartWatchAppIcon />,
    fitness: <FitnessWearableIcon />,
    healthcare: <HealthcareMedIcon />,
    lifestyle: <LifestyleWearablesIcon />,
    security: <SecurityWearablesIcon />,
    safety: <SafetyWearablesIcon />,
  }
  return (
    <Layout>
      <Helmet title={'Wearable Tech'} />
      <PageHero image={headerImage} />
      <Heading title="Wearable Technology" noHeadingIcon={true}>
        <p>
          Our wearable technology software captures the device's haptic and
          visual sensors, expedites bi-directional M2M data exchanges, summaries
          captured data and responds to inherent controls and environmental
          feedback. This mostly includes body-borne computers i.e. devices that
          can be worn on the face, wrist, ankles, clothes or even shoes! Long
          story short, we offer advanced wearable development services to fulfil
          various business, customer and enterprise purposes.
        </p>

        <p>
          Right from strategy, design, development to deployment, CodeMyMobile
          is your trusted partner in the wearable app development process.
        </p>
      </Heading>
      <Container>
        <SectionHeading>Solutions and Services</SectionHeading>
        <ListWrapper>
          <ListItem>Wearable Technology Software Solutions</ListItem>
          <ListItem>Wearables User Experience & Interface Design</ListItem>
          <ListItem>Back-end Systems Development</ListItem>
          <ListItem>API Development</ListItem>
          <ListItem>Augmented Reality Apps and Games for Wearables</ListItem>
          <ListItem>Wearables App Development Consultation</ListItem>
        </ListWrapper>
      </Container>
      <ProcessWrapper>
        <Container>
          <SectionHeading>Our Proficiency</SectionHeading>
          <IconWrapper>
            {ProficiencyList.map((item, id) => {
              return (
                <ProcessIconElem key={id}>
                  {iconMap[item.type]}
                  <ItemTitle>{item.title}</ItemTitle>
                  <Content>{item.content}</Content>
                </ProcessIconElem>
              );
            })}
          </IconWrapper>
        </Container>
      </ProcessWrapper>
    </Layout>
  );
};

export default WearableTech;
